import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../layout"
import Seo from "gatsby-plugin-wpgraphql-seo"
import "./PageDeviceDetector.scss"
import Image from "../Image/Image"
import HTMLParser from "html-react-parser"

const PageDeviceDetector = ({ data }) => {
  const { wpPage: page } = data
  const {
    title,
    description,
    image,
    imageText,
    playStoreUrl,
    appStoreUrl,
    redirectUrl,
  } = page.ACFDeviceDetectorPage
  const [isDesktop, setIsDesktop] = useState(false)

  useEffect(() => {
    const userAgent =
      typeof window !== "undefined" &&
      (navigator.userAgent || navigator.vendor || window.opera)

    if (
      typeof window !== "undefined" &&
      (userAgent.match(/iPad/i) ||
        userAgent.match(/iPhone/i) ||
        userAgent.match(/iPod/i))
    ) {
      window.location.replace(appStoreUrl)
      setIsDesktop(false)
    } else if (typeof window !== "undefined" && userAgent.match(/Android/i)) {
      window.location.replace(playStoreUrl)
      setIsDesktop(false)
    } else {
      setIsDesktop(true)
    }
  }, [appStoreUrl, playStoreUrl])

  if (!isDesktop) return null
  if (redirectUrl) {
    window.location.replace(redirectUrl)
  }
  return (
    <Layout
      menuColor={page.whitemenucheckbox.whiteMenu}
      hideNavigation={page.whitemenucheckbox.hideNavigation}
      hideButton={page.whitemenucheckbox.hideButton}
      cleanFooter={page.whitemenucheckbox.cleanFooter}
      whiteBackgroundMenu={page.whitemenucheckbox.whiteBackgroundMenu}
      hideBanner={page.ACFBanner.hideBanner}
    >
      <Seo post={page} />
      <div className="page page-device-detector">
        <div className="container">
          <div className="page-device-detector__wrapper">
            <h5 className="page-device-detector__title">{title}</h5>
            <div className="page-device-detector__description">
              {HTMLParser(description)}
            </div>
            <div className="page-device-detector__image-wrapper">
              <Image image={image} />
            </div>
            <div className="page-device-detector__image-description">
              {HTMLParser(imageText)}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

PageDeviceDetector.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PageDeviceDetector

export const pageQuery = graphql`
  query PageDeviceDetectorById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      whitemenucheckbox {
        whiteMenu
        hideNavigation
        hideButton
        cleanFooter
        whiteBackgroundMenu
      }
      ACFBanner {
        hideBanner
      }
      ACFDeviceDetectorPage {
        title
        description
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            extension
            publicURL
          }
        }
        imageText
        playStoreUrl
        appStoreUrl
        redirectUrl
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            extension
            publicURL
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            extension
            publicURL
          }
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`
